.main-page {
    height: 100vh;
    overflow: hidden;
}

.main-page .container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.main-title {
    font-family: 'Carter', sans-serif;
    font-size: 56px;
    line-height: 1.31;
    text-align: center;
    color: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    box-sizing: border-box;
    margin-bottom: 24px;

    span {
        font-size: 24px;
        line-height: 1.25;
        color: #25b1cb;
        margin-top: 10px;
    }
}

.main-subtitle {
    font-size: 24px;
    line-height: 1.67;
    margin-bottom: 0;
    color: #f5f5f5;
}

p {
    text-align: center;
    line-height: 1.38;
    margin-bottom: 8px;
}

.ru .main-title {
    font-size: 22px;
}

.wr-slider {
    // flex-grow: 1;
    position: relative;
    margin-top: 6vh;
    margin-bottom: 6vh;

    .pumpkin-1 {
        position: absolute;
        width: 160px;
        left: calc(50% + 60px);
        bottom: -40px;
        z-index: 10;
    }
    
    .steam {
        position: absolute;
        mix-blend-mode: screen;
        width: 500px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: -5;
    }
}

.wr-slider img {
    width: 100%;
}

.wr-slider .slick-list,
.wr-slider .slick-slider {
    position: relative;
}

.wr-slider .slick-dots li {
    width: 4px;
    height: 4px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    vertical-align: middle;
    margin: 0 8px;
}

.wr-slider .slick-dots button {
    width: 100%;
    height: 100%;
    margin: 0;
}

.wr-slider .slick-dots button {
    background-color: #fff;
    border-radius: 3px;
}

.wr-slider .slick-dots .slick-active button {
    background-color: #fff;
}

.wr-slider .slick-dots .slick-active {
    width: 30px;
}

.wr-slider .slick-dots {
    bottom: -40px;
}

.slide {
    display: flex;
    border-radius: 50px;
    width: 270px;
    height: 270px;
    box-sizing: border-box;
    overflow: hidden;
    transition: .6s;
    margin: 0 auto;

    img {
        width: 100%;
        height: 100%;
    }
}

.slick-current {
    .slide {
        transform: rotate(5deg);
    }
}

.slide-container {
    padding: 10px 0;
}

// .slick-slide {
//     margin: 0 15px;
// }

// .slick-list {
//     margin: 0 -15px;
// }

.slide-item {
    margin: 0 7px 14px;
}

.slide-item h2 {
    font-size: 16px;
    font-weight: bold;
    font-style: oblique;
    line-height: 1;
    text-align: center;
    color: #fff;
    margin-bottom: 10px;
}

.slide-image-container {
    max-width: 140px;
    border-radius: 16px;
    overflow: hidden;
}

.slide-image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.slide-item-active {
    position: relative;
}

.slide-item-active .slide-image-container {
    box-shadow: 0 0 120px 0 #7ecd21;
    border: 2px solid #7ecd21;
    box-sizing: border-box;
    max-width: 200px;
}

.btn-upload-foto {
    font-size: 24px;
    font-weight: bold;
    color: #1a3b3b;
    text-transform: uppercase;
    max-width: 320px;
    width: 90%;
    height: 80px;
    border-radius: 100px;
    background-color: #95e09e;
    display: block;
    flex-shrink: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    margin: 0 auto;

    &:hover {
        opacity: .6;
    }
}

.btn-another-foto {
    font-size: 15px;
    font-weight: bold;
    color: #f2bc19;
    display: flex;
    align-items: center;
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
    &:hover {
        opacity: .6;
    }
}

.create-page h2 {
    font-weight: bold;
    color: #333;
    line-height: 1.38;
    text-align: center;
    margin-bottom: 20px;
}

.create-page .create-steps {
    line-height: 1.38;
    text-align: center;
    color: #999;
    margin-bottom: 8px;
}

.creatives {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 30px;
}

.creative {
    width: 48%;
    position: relative;
    margin: 0 0 15px;
}

.creative .num-check-foto {
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    position: absolute;
    top: 8px;
    right: 8px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #05b0ff;
    display: none;
    align-items: center;
    justify-content: center;
}

.creative .holder {
    position: relative;
    margin-bottom: 8px;
    padding-top: 100%;
    background-color: #eeeeee;
}

.creative.active .num-check-foto {
    display: flex;
}

.creative img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.creative.active img {
    // box-shadow: 0 0 40px 0 #c77dff;
    border: solid 3px #05b0ff;
    box-sizing: border-box;
}

.creative p {
    font-size: 16px;
    line-height: 1;
    text-align: center;
    color: #333;
    text-transform: capitalize;
}

.create-page .btn-upload-foto {
    position: fixed;
    bottom: 15px;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
}

.create-page .btn-upload-foto.disabled {
    background-color: #3b3c3e;
}

.collage-page {
    display: flex;
    flex-direction: column;
    height: 90%;
    // overflow: hidden;
    padding-bottom: 0;
    .btn-upload-foto {
        max-width: 200px;
    }
    .btn-back {
        margin-bottom: 8px;
    }
    .footer-links {
        display: none;
    }
}

.btns-container {
    position: relative;
}

.btn-refresh-foto {
    font-size: 10px;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(110px, 5px);
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    padding: 5px;
    svg {
        width: 24px;
    }
}

.collage-page .creative-holder {
    position: relative;
    max-width: 450px;
    width: 100%;
    margin: 0 auto 24px;

    .creative-holder-placeholder {
        padding-top: 100%;
    }

    .wait-video, video, img {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        z-index: 20;
    }

    .imageview-host {
        z-index: 20;
    }

    .wait-video {
        z-index: 5;
    }
}

.collage-page img,
.collage-page video {
    width: 100%;
    max-width: 450px;
    // max-height: 450px;
    height: 100%;
    display: block;
    margin: 0 auto;
}

.collage-page img {
    object-fit: contain;
    width: auto;
    max-width: 100%;
    max-height: 100%;
    border-radius: 24px;
    height: auto;
}
.collage-page video {
    object-fit: contain;
}

.collage-page .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.collage-page-content {
    // flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.collage-page-text {
    margin-bottom: 27px;
}

/*loader*/
.loader {
    overflow: hidden;
    width: 100%;
    height: 100vh;
    position: relative;
}

.collage-page-text {
    margin-bottom: 27px;
}

.loader p {
    font-size: 24px;
    line-height: 1.25;
    text-align: center;
    color: #fff;
    position: absolute;
    top: calc(40% + 220px);
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
}

.spinner-container h3 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.avatar-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 142px;
    height: 142px;
    box-sizing: border-box;
    border-radius: 50%;
    object-fit: cover;
    object-position: top;
    animation-name: fadein;
    animation-duration: 320ms;
    animation-play-state: running;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
}

.spinner-container {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);

    h3 {
        font-family: 'Carter';
        font-size: 28px;
        line-height: 1;
        color: #95e09e;
        width: 140px;
        height: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: solid 2px rgba(255, 255, 255, 0.25);
        border-radius: 50%;
    }
}

.triple-spinner {
    display: block;
    position: relative;
    width: 280px;
    height: 280px;
    border-radius: 50%;
    border: solid 1px rgba(255, 255, 255, 0.15);
}

.triple-spinner2 {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 230px;
    height: 230px;
    border-radius: 50%;
    border: solid 1px rgba(255, 255, 255, 0.15);
}

.triple-spinner::before,
.triple-spinner2::before  {
    content: "";
    position: absolute;
    border-radius: 50%;
    border: 1px solid transparent;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.triple-spinner::before {
    border: 1px solid #ffc13a;
    border-bottom-color: transparent;
    border-right-color: transparent;
    animation: spin-reverse 1.5s linear infinite;
}

.triple-spinner2::before {
    border: 1px solid #95e09e;
    border-top-color: transparent;
    border-left-color: transparent;
    animation: spin 3s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes spin-reverse {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(-360deg);
    }
}

/*modal*/
.-show-popup {
    overflow: hidden;
}

.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .7);
    z-index: 30;
}

.modal {
    max-width: 380px;
    width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 18px;
    background-color: #fff;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 26px 16px;
    svg {
        position: absolute;
        top: 0;
        right: -2px;
        left: -2px;
        width: calc(100% + 4px);
        z-index: -5;
    }
    img {
        width: calc(100% + 32px);
        margin-left: -16px;
        margin-top: -40px;
    }
    p {
        font-size: 16px;
        line-height: 1.5;
        text-align: center;
        color: #000;
        margin-bottom: 24px;
    }
    .btn-upload-foto {
        color: #fff;
        max-width: 100%;
        width: 100%;
    }
    h3 {
        font-family: 'Carter';
        font-size: 36px;
        line-height: 1.25;
        text-align: center;
        color: #0e0e0e;
        margin-bottom: 14px;
    }
}

.btn-close {
    background-color: #95e09e;
    color: #1a3b3b;
    text-transform: uppercase;
    font-weight: bold;
    max-width: 240px;
    width: 90%;
    height: 48px;
    border-radius: 100px;
}

/*error-page*/
.error-page .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 94vh;
}

.error-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 1.38;
}

.error-page svg {
    max-width: 264px;
    margin-bottom: 5.5vh;
}

.error-page h3 {
    font-weight: bold;
    margin-bottom: 16px;
}

.btn-back {
    font-size: 15px;
    font-weight: bold;
    color: #fff;
    display: flex;
    align-items: center;
    background-color: transparent;
    position: relative;
    top: -5px;
    left: -5px;
    padding: 5px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    margin-bottom: 9px;
}

.btn-back svg {
    width: 11px;
    margin-right: 10px;
}

.collage-page h2 {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.38;
    text-align: center;
    color: #fff;
    width: 100%;
    margin-bottom: 16px;
    text-transform: uppercase;
}

.share-container {
    display: flex;
    align-items: center;
    overflow-x: auto;
    // position: absolute;
    // bottom: 0;
    // left: 0;
    // right: 0;
    position: relative;
    // left: -30px;
    // width: calc(100% + 60px);
    padding-top: 12px;
    padding-bottom: 12px;
    // border-top: 1px solid #d2d2d2;
    // border-bottom: 1px solid #d2d2d2;
    // margin-top: 10px;
}

.share-container button {
    width: 40px;
    height: 40px;
    border: none;
    background: no-repeat;
    flex-shrink: 0;
    margin-left: 24px;

    &:last-child {
        width: 1px;
    }
}

.share-container svg {
    width: 100%;
}

.steps-progress {
    position: fixed;
    height: 4px;
    width: 100%;
    background-color: #e7e7e7;
    top: 0;
    left: 0;
    z-index: 50;

    span {
        transition: 500ms;
        background-color: #feca28;
        display: block;
        height: 100%;
    }
}

.tabs-container {
    display: flex;
    justify-content: center;
    margin: 0 auto 14px;
    width: 100%;

    button {
        font-family: 'Helvetica', sans-serif;
        font-size: 16x;
        font-weight: bold;
        line-height: 1;
        letter-spacing: 2px;
        text-align: center;
        border-radius: 35px;
        border-bottom: 3px solid transparent;
        color: #fff;
        flex-shrink: 0;
        height: 48px;
        max-width: 185px;
        width: 45%;
        background: none;
        box-sizing: border-box;
        margin: 0 5px;

        svg {
            width: 64%;
        }
    }

    .active-tab {
        border-bottom: 3px solid #95e09e;
    }
}

.wait-video {
    overflow: hidden;
    max-width: 450px;
    margin: 0 auto;

    img {
        filter: blur(7px);
    }
}

.loader-video {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 10;
}

.loader-video .item-loader {
    width: 9px;
    height: 9px;
    border-radius: 50%;
    animation: item-loader .9s linear infinite;
    margin: 0 6px;
}

.loader-video .first-item-loader {
    background-color: #1db8ff;
}

.loader-video .second-item-loader {
    background-color: #ffd300;
    animation: item-loader .9s linear infinite;
    animation-delay: .3s;
}

.loader-video .third-item-loader {
    background-color: #fc4700;
    animation: item-loader .9s linear infinite;
    animation-delay: .6s;
}


@keyframes item-loader {
    0% {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(1.8, 1.8);
    }
}

.root, .root > div, body, html {
    height: 100%;
}

.collage-container {
    flex-grow: 1;
    .container {
        position: relative;
        height: 100%;
        width: 100%;
        padding: 0;
    }
}

.collage-page {
    padding-top: 32px;
}

.collage {
    height: 100%;
    width: 100%;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    min-height: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    max-width: 90%;
    display: flex;
    align-items: center;
    margin: 16px auto;
}

.tab-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.templates-container {
    flex-shrink: 0;
    max-width: 640px;
    margin: 0 auto;
    padding-top: 2px;
    padding-bottom: 2px;
    
    .container {
        display: flex;
        align-items: center;
        flex-direction: row;
        overflow: auto;
        width: 100%;
        max-width: 100%;
        padding-top: 2px;
        padding-left: 2px;
        padding-right: 2px;
        padding-bottom: 10px;

        &::-webkit-scrollbar-track {
            background-color: rgba(255, 255, 255, 0.15);
            height: 10px;
        }

        &::-webkit-scrollbar {
            padding: 10px 0;
            height: 2px;
            background-color: rgba(255, 255, 255, 0.15);
        }

        &::-webkit-scrollbar-thumb {
            background-color: #fff;
        }
    }

    button {
        flex-shrink: 0;
        overflow: auto;
        background-size: cover;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        font-size: 8px;
        text-align: center;
        color: #fff;
        padding: 0 6px 4px;

        span {
            position: relative;
            z-index: 10;
        }

        &.named {
            &:after {
                content: "";
                display: block;
                width: 100%;
                height: 25px;
                background-image: linear-gradient(
                                180deg,transparent,#000);
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }
    }
}

.btn-choice-template {
    // width: 96px;
    // height: 96px;
    margin-left: 8px;
    opacity: 0.75;
    width: 68px;
    height: 68px;
    border-radius: 14px;
    // border-radius: 24px;

    &.active {
        border: 3px solid #fff;
        opacity: 1;
    }
}

.halloween-body-background img {
    display: block;
    width: 80%;
    height: auto;
    margin: auto;
}

.root > div:not(.loader-container) {
    display: flex;
    flex-direction: column;
    height: 100%;
}

/*loader*/
.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    p {
        color: #fff;
    }
    &.android {
        flex-direction: column;
        p {
            margin-bottom: 14px;
        }
    }
}

.loader-android {
    width: 200px;
    height: 2px;
    background-color: rgba(5, 176, 255, 0.2);
    position: relative;
    overflow: hidden;
    span {
        position: absolute;
        top: 0;
        height: 100%;
        width: 70px;
        background-color: #05b0ff;
        animation: line-loader .9s linear infinite;
    }
    @keyframes line-loader {
        0% {
            left: -70px;
        }

        100% {
            left: calc(100% + 70px);
        }
    }
}

.loader-circle {
    display: flex;
    margin-bottom: 24px;

    &-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
  
    .item-loader {
        width: 9px;
        height: 9px;
        border-radius: 50%;
        animation: item-loader .9s linear infinite;
        background-color: #fff;
        opacity: 1;
        margin: 0 6px;
    }
    
    .second-item-loader {
        animation: item-loader .9s linear infinite;
        animation-delay: .3s;
    }
    
    .third-item-loader {
        animation: item-loader .9s linear infinite;
        animation-delay: .6s;
    }
}
  
  
@keyframes item-loader {
    0% {
          transform: scale(1, 1);
          opacity: 0;
    }
    80% {
          transform: scale(1.8, 1.8);
          opacity: 1;
    }
}

.btn-done {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    color: #1a3b3b;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 180px;
    height: 48px;
    border-radius: 40px;
    background-color: #95e09e;
    margin: 0 auto;
}

.footer-btns {
    position: relative;
    max-width: 510px;
    width: 100%;
    margin: 30px auto 0;

    &.invisible {
        visibility: hidden;
        pointer-events: none;
    }

    .btn-back {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 8px;
        font-weight: 500;
        text-transform: uppercase;
        color: #95e09e;
        background: border-box;
        width: 58px;
        position: absolute;
        left: 5%;
        top: 0;
        padding: 0;
        &-container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            border: solid 3px #95e09e;
            box-sizing: border-box;
            display: flex;
            margin-bottom: 5px;
        }
        svg {
            width: 8px;
            margin: 0;
        }
        p {
            font-size: 12px;
            font-weight: 500;
            text-transform: uppercase;
            color: #95e09e;
            margin: 0;
        }
    }
}

.step {
    font-weight: 600;
    font-size: 16px;
    font-style: italic;
    color: #305f3d;
    line-height: 1;
    letter-spacing: 0.5px;
    text-align: center;
    width: 100%;
    margin-bottom: 8px;
}

.try-photo {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 8px;
    font-weight: 500;
    text-transform: uppercase;
    color: #95e09e;
    background: border-box;
    width: 58px;
    position: absolute;
    right: 5%;
    top: 0;

    &-container {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        border: solid 3px #95e09e;
        box-sizing: border-box;
        display: flex;
        margin-bottom: 5px;
    }

    img {
        width: 20px;
        border-radius: 0;
    }
    
}

.pumpkin-2 {
    position: absolute;
    z-index: -1;
    top: 160px;
    left: calc(50% - 270px);
    width: 160px;

    &-small {
        width: 110px;
        top: 80px;
        left: calc(50% + 150px)
    }
}
.error-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
        padding: 0 10px;
    }

    button {
        width: 240px;
        height: 48px;
        border-radius: 100px;
        background-color: #95e09e;
        font-size: 16px;
        font-weight: 800;
        line-height: 1;
        text-align: center;
        color: #1a3b3b;
        text-transform: uppercase;
        margin-top: 24px;
    }
}

@media all and (max-height: 850px) and (orientation: landscape) {
    .main-title {
        font-size: 36px;
        margin-bottom: 16px;
    }

    .main-subtitle {
        font-size: 16px;
        line-height: 1.67;
        margin-bottom: 0;
    }

    .wr-slider {
        margin-top: 3vh;
        margin-bottom: 3vh;
    }

    .wr-slider .slick-dots {
        bottom: -30px;
    }
    .btn-upload-foto {
        height: 68px;
    }

    .slide {
        width: 230px;
        height: 230px;
    }
}

@media all and (max-height: 660px) and (orientation: landscape) {
    .collage-page .creative-holder,
    .tabs-container {
        max-width: 280px;
    }

    .collage-page .container {
        max-width: 310px;
    }
}

@media all and (max-height: 620px) {
    .main-page {
        .container {
            padding: 16px 10px 0;
        }
    }
    .wr-slider {
        margin-top: 3vh;
        margin-bottom: 3vh;
    }
    .wr-slider .slick-dots {
        bottom: -24px;
    }
}

@media all and (max-height: 580px) {
    .collage-page .btn-back {
        margin-bottom: 10px;
    }
    .collage-page .creative-holder {
        margin-bottom: 16px;
    }
}

@media all and (max-height: 520px) {
    .collage-page .container {
        padding-top: 12px;
    }
    .collage-page .btn-back {
        margin-bottom: 8px;
    }
    .collage-page {
        padding-bottom: 0;
    }
    .collage-page .creative-holder {
        margin-bottom: 12px;
    }
    .slide {
        width: 220px;
        height: 220px;
    }
}

@media all and (max-width: 780px) {
    .tabs-container {
        margin-bottom: 30px;
        button {
            font-size: 16px;
            height: 48px;
            max-width: 185px;
            width: 45%;
        }
    }
    .step {
        font-size: 16px;
        margin-bottom: 8px;
    }

    .collage {
        margin: 8px auto;
    }

    .templates-container {
        max-width: 100%;
    }

    .btn-choice-template {
        width: 68px;
        height: 68px;
        border-radius: 14px;
    }

    .footer-btns {
        margin: 40px auto 0;
    }
    .btn-done {
        font-size: 16px;
        max-width: 180px;
        height: 48px;
    }

    .try-photo {
        font-size: 8px;
        width: 58px;
        right: 5%;
    }

    .try-photo img {
        width: 20px;
    }

    .try-photo-container {
        width: 48px;
        height: 48px;
        border: solid 3px #95e09e;
        margin-bottom: 5px;
    }

    .footer-btns .btn-back {
        font-size: 8px;
        width: 58px;
        left: 5%;
    }

    .footer-btns .btn-back svg {
        width: 8px;
        margin: 0;
    }

    .footer-btns .btn-back-container {
        width: 48px;
        height: 48px;
        border: solid 3px #95e09e;
        margin-bottom: 5px;
    }
}

@media all and (max-width: 540px) {
    .main-title {
        font-size: 34px;
        margin-bottom: 14px;
    }

    .main-page {
        .footer-links {
            display: flex;
            bottom: 8px;
        }
    }

    .pumpkin-2-small {
        width: 90px;
        left: calc(50% + 50px);
    }

    .collage-page {
        height: 90%;
        padding-top: 16px;
    }

    .main-subtitle {
        font-size: 16px;
        line-height: 1.5;
        margin-bottom: 0;
    }

    .slide {
        width: 210px;
        height: 210px;
    }

    .wr-slider .pumpkin-1 {
        width: 90px;
        left: calc(50% + 40px);
        bottom: -20px;
    }

    .btn-upload-foto {
        font-size: 16px;
        max-width: 240px;
        height: 48px;
    }
}

@media all and (max-width: 500px) {
    .share-container {
        left: -30px;
        width: calc(100% + 60px);
    }
}

@media all and (max-width: 370px) {
    .container {
        padding: 27px 10px 0;
    }

    .tabs-container button {
        font-size: 14px;
    }
}

@media all and (max-width: 340px) {
    .modal {
        padding: 76px 16px 16px;
    }

    .btn-done {
        max-width: 160px;
    }
}

@media all and (max-height: 530px) {
    .main-title {
        font-size: 24px;
        margin-bottom: 8px;
    }

    .main-subtitle {
        font-size: 14px;
    }
    .slide {
        width: 160px;
        height: 160px;
    }
}
