.imageview-host {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background-color: rgba(0, 0, 0, .1);

  .imageview-holder {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  .imageview-holder,
  .imageview-image {
    animation: imageview-show 320ms;
  }
}

@keyframes imageview-show {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.footer-links {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  margin: 20px 0 5px;

  a {
    color: #fff;
    opacity: 0.6;
    text-decoration: underline;
    margin: 0 20px;
  }

  @media all and (max-width: 540px) {
    display: none;
  }
}